export default {
  GET(state, data) {
    state.allocation = { ...data }
  },
  GET_BY_DAY(state, data) {
    state.allocationByDay = { ...data }
  },
  SWITCH_TO_CALENDAR_TABLE(state, bool) {
    state.isSwitchToCalendarTable = bool
  },
  ALL_STATES_DIS_BTN(state, bool) {
    state.allStateDisBtn = bool
  },
  CHANGE_FILTER(state, filter) {
    state.buttonFilters = filter
  },
}
