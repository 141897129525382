import axios from '@/libs/axios'

const get = ({ commit }, params) => new Promise((resolve, reject) => {
  axios.get('/allocations/', {
    params,
  })
    .then(response => {
      commit('GET', response.data)
      commit('CHANGE_FILTER', Object.keys(response.data.enums || {})[0])
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
const getCalendarDay = ({ commit }, params) => new Promise((resolve, reject) => {
  axios.get('/allocation-by-day/', {
    params,
  })
    .then(response => {
      commit('GET_BY_DAY', response.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
export default {
  get,
  getCalendarDay,
}
